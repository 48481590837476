import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import MainCircularProgress from "./MainCircularProgress";
export const About = (props) => {

const dataReturned=useQuery(
  {
    queryKey:["aboutUsData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/about-us/?website=Company`)
    .then((res)=>res.data),
  }
)
console.log(dataReturned.data)

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
          {dataReturned.data ? dataReturned.data.map((d)=>(
            <img src={process.env.REACT_APP_BACKEND_URL+d.logo} className="img-responsive" alt="" />
          )):<CircularProgress/>
          }
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{dataReturned.data ? dataReturned.data[0].description : <MainCircularProgress/>}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

