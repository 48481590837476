import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

export const Testimonials = (props) => {
 const fetchedData = useQuery({
    queryKey: ['servicesDatas'],
    queryFn: () =>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/testimonial/?website=Company`)
    .then((res) =>res.data,
      ),
  })

if(fetchedData.data){
console.log(fetchedData.data);
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {fetchedData.data
            ? fetchedData.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={process.env.REACT_APP_BACKEND_URL+d.image} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.description}"</p>
                      <div className="testimonial-meta"> - {d.full_name} </div>
                        <Stack spacing={1}>
           <Rating name="half-rating-read" defaultValue={parseInt(d.rating)} precision={0.5} readOnly />
                </Stack>

                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
}
};
