import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Truncate } from "@re-dev/react-truncate";

export const Services = () => {

const dataReturned=useQuery(
  {
    queryKey:["servicesData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/service/?website=Individual`)
    .then((res)=>res.data),
  }
)
if(dataReturned.isLoading){
  return <span>..Loading</span>
}

if(dataReturned.data){
  console.log(dataReturned.data);
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            SERVICES THAT WE OFFER
          </p>
        </div>
        <div className="row">
          {dataReturned.data
            ? dataReturned.data.map((d, i) => (
                <div key={`${d.id}-${i}`} className="col-md-4">
                  {" "}
                  <img style={{height:150}} src={process.env.REACT_APP_BACKEND_URL+d.image}/>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <Truncate
      lines={2}
      ellipsis={
        <span>
          ... <a target={"_blank"} href={d.url} style={{color:"orange"}}>Read more</a>
        </span>
      }
      onTruncate={(didTruncate) => {
        console.log(didTruncate)
      }}
    >
                    {d.description}
                    </Truncate>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
}
};

