import React from 'react'

const Error = () => {
  return (
    <div style={{padding:"10rem"}}>
        <h1>

        404 Not Found!
        </h1>

        </div>
  )
}

export default Error;