import { useState } from "react";
import { CircularProgress } from "@mui/material";

function Button({mutation}) {
    return (
      <button type="submit" className="btn btn-custom btn-lg" disabled={mutation.isPending?true:false}>
       {mutation.isSuccess?"Sent":(mutation.isPending?<CircularProgress/>:"Send Message")}
     </button>
    )
}
export default Button;

