import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Image } from "./image";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Team = (props) => {
  const fetchedData = useQuery({
    queryKey: ['teamData'],
    queryFn: () =>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/team-member/?website=Company`)
    .then((res) =>res.data,
      ),
  })

  if(fetchedData.isLoading){
    return(
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

if(fetchedData.data){
  console.log(fetchedData.data)
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            INTRODUCING OUR DYNAMIC TEAM
          </p>
        </div>
        <div id="row">
          {fetchedData.data
            ? fetchedData.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
            <Image
                      title={d.full_name}
                      email={d.email}
                      largeImage={process.env.REACT_APP_BACKEND_URL+d.image}
                      smallImage={process.env.REACT_APP_BACKEND_URL+d.image}
                    />

                    <div className="caption">
                      <h4>{d.full_name}</h4>
                      <p>{d.description}</p>
                      <p>{d.email}</p>
                         </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
}
};
