import { CircularProgress, Box } from "@mui/material";
CircularProgress
function MainCircularProgress() {
    return (
             <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box> 
    )
}


export default MainCircularProgress;
