import React from "react";
import { Link } from "react-router-dom";

export const Header = (props) => {
  return (
      <div className="intro">
        <div className="introDiv">
          <div className="introTitle" style={{textAlign:"center"}}>
                  {props.data ? props.data.title : "Loading"}
                </div>
                  <div className="introDescription" style={{textAlign:"center"}}>An initiative by RP Srijan & Sangita Timilsina</div>

                  <div>
                    <Link
                  to="/services"
                  className="btn btn-custom btn-lg page-scroll"
                   >
                    Our Services
                </Link>
                  </div>

                         </div>
                     {/* <div className="overlay">
          <div className="container">
            <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1>
                  {props.data ? props.data.title : "Loading"}
                  <br></br>
                  <span>An initiative by RP Srijan & Sangita Timilsina</span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  CV Maker
                </a>{" "}
              </div>
            </div>
          </div>
        </div> */}
      </div>
  );
};
