import { Link } from "react-router-dom";

function Logo() {
  return (
    <Link to="/">
      <img src="../img/main_logo.png" alt="RP Srijan logo" style={{width:60}} />
    </Link>
  );
}

export default Logo;